export const DEFAULT_SEO = {
  title: "SYLTARE",
  description: "SYLTARE : P2E TRADING NFT CARD GAME",
  canonical: "https://www.syltare.com/",
  openGraph: {
    type: "website",
    locale: "en",
    url: "https://www.syltare.com/",
    title: "SYLTARE",
    description: "SYLTARE : P2E TRADING NFT CARD GAME",
    site_name: "SYLTARE",
    images: [
      {
        url: "https://assets.syltare.com/images/preview.png",
        width: 800,
        height: 420,
        alt: "SYLTARE : P2E TRADING NFT CARD GAME",
      },
    ],
  },
  twitter: {
    handle: "@syltare",
    site: "@syltare",
    cardType: "summary_large_image",
  },
};
