import { useTranslation } from "next-i18next";
import Image from "next/image";
import style from "./footer.module.scss";

export default function Footer() {
  const { t } = useTranslation("footer");

  return (
    <footer className={style.footer}>
      <Image quality={100} src="/syltare_big_logo.png" width={290} height={60} />
      <div className={style.logo}>
        {t("contact")}: team@syllabs.io <br />
        COPYRIGHT ⓒ SYLLABS LTD. ALL RIGHTS RESERVED <br />
        111 SOMERSET ROAD #06-07L 111 SOMERSET SINGAPORE 238164
      </div>
    </footer>
  );
}
