export { ReactComponent as ICON_ADD } from "./add_black_24dp.svg";
export { ReactComponent as ICON_ADD_CIRCLE } from "./add_circle_black_24dp.svg";
export { ReactComponent as ICON_ADD_CIRCLE_OUTLINE } from "./add_circle_outline_black_24dp.svg";
export { ReactComponent as ICON_ADD_TO_PHOTOS } from "./add_to_photos.svg";
export { ReactComponent as ICON_ARROW } from "./arrow.svg";
export { ReactComponent as ICON_ARROW_LEFT } from "./arrow_left.svg";
export { ReactComponent as ICON_ARROW_LEFT_GREEN } from "./arrow_left_green.svg";
export { ReactComponent as ICON_ARROW_RIGHT } from "./arrow_right.svg";
export { ReactComponent as ICON_ARROW_RIGHT_THIN } from "./arrow_right_thin.svg";
export { ReactComponent as ICON_ARROW_RIGHT_YELLOW } from "./arrow_right_yellow.svg";
export { ReactComponent as ICON_WAND } from "./auto_fix_normal_black_24dp.svg";
export { ReactComponent as ICON_BATTERY } from "./battery_4_bar_black_24dp.svg";
export { ReactComponent as ICON_BOLT } from "./bolt_black_24dp.svg";
export { ReactComponent as ICON_BOLT_SLIM } from "./bolt_slim_24dp.svg";
export { ReactComponent as ICON_PLUS } from "./box_add_24dp.svg";
export { ReactComponent as ICON_BUG_REPORT_FILL } from "./bug_report-fill.svg";
export { ReactComponent as ICON_BUG_REPORT_OUTLINE } from "./bug_report-outline.svg";
export { ReactComponent as ICON_MINUS } from "./box_subtract_24dp.svg";
export { ReactComponent as ICON_CANCEL } from "./cancel_black_24dp.svg";
export { ReactComponent as ICON_CATEGORY } from "./category.svg";
export { ReactComponent as ICON_CHAIR } from "./chair.svg";
export { ReactComponent as ICON_CHAT } from "./chat.svg";
export { ReactComponent as ICON_CHAT_NO } from "./chat_no.svg";
export { ReactComponent as ICON_CHECKBOX } from "./check_box_black_24dp.svg";
export { ReactComponent as ICON_CHECKBOX_BLANK } from "./check_box_outline_blank_black_24dp.svg";
export { ReactComponent as ICON_CHECK_CIRCLE } from "./check_circle_black_24dp.svg";
export { ReactComponent as ICON_CIRCLE } from "./circle.svg";
export { ReactComponent as ICON_CLOSE } from "./close_black_24dp.svg";
export { ReactComponent as ICON_COPY } from "./copy.svg";
export { ReactComponent as ICON_CROWN_FIRST } from "./crown_first.svg";
export { ReactComponent as ICON_CROWN_FOURTH } from "./crown_four.svg";
export { ReactComponent as ICON_CROWN_SECOND } from "./crown_second.svg";
export { ReactComponent as ICON_CROWN_THIRD } from "./crown_third.svg";
export { ReactComponent as ICON_CHECK } from "./done_red_24dp.svg";
export { ReactComponent as ICON_DUNGEON_OF_COURAGE } from "./icon_dungeon_of_courage.svg";
export { ReactComponent as ICON_EDIT } from "./edit_black_24dp.svg";
export { ReactComponent as ICON_ELEMENTAL } from "./elemental_24dp.svg";
export { ReactComponent as ICON_ELEMENTAL_GRADIENT } from "./elemental_gradient_24dp.svg";
export { ReactComponent as ICON_EMOJI } from "./emoji.svg";
export { ReactComponent as ICON_TROPHY } from "./emoji_events_black_24dp.svg";
export { ReactComponent as ICON_EMPTY_SYL } from "./empty_syl.svg";
export { ReactComponent as ICON_ERROR_TRIANGLE } from "./error_triangle_24dp.svg";
export { ReactComponent as ICON_EXPAND_MORE } from "./expand_more_black_24dp.svg";
export { ReactComponent as ICON_LESS_MORE } from "./expand_less_black_24dp.svg";
export { default as Facebook } from "./facebook.svg";
export { ReactComponent as ICON_HEART } from "./favorite_black_24dp.svg";
export { ReactComponent as ICON_FILTER } from "./filter_alt_black_24dp.svg";
export { ReactComponent as ICON_FIRST_PLACE } from "./first_place.svg";
export { ReactComponent as ICON_FOURTH_PLACE } from "./fourth_place.svg";
export { default as Github } from "./github.svg";
export { ReactComponent as ICON_GLOBAL } from "./global.svg";
export { ReactComponent as ICON_HELP } from "./help.svg";
export { ReactComponent as ICON_HOME } from "./home_black_24dp.svg";
export { ReactComponent as ICON_DISCORD } from "./icon_discord.svg";
export { ReactComponent as ICON_KAKAOTALK } from "./icon_kakaotalk.svg";
export { ReactComponent as ICON_MEDIUM } from "./icon_medium.svg";
export { ReactComponent as ICON_OPENSEA } from "./icon_opensea.svg";
export { ReactComponent as ICON_TELEGRAM } from "./icon_telegram.svg";
export { ReactComponent as ICON_TWITTER } from "./icon_twitter.svg";
export { ReactComponent as ICON_YOUTUBE } from "./icon_youtube.svg";
export { ReactComponent as ICON_YOUTUBE_COLOR } from "./icon_youtube-color.svg";
export { ReactComponent as ICON_INFO } from "./info_black_24dp.svg";
export { ReactComponent as ICON_INVENTORY } from "./inventory.svg";
export { ReactComponent as ICON_LEADERBOARD_YELLOW } from "./leaderboard_yellow.svg";
export { ReactComponent as ICON_LIGHTBULB } from "./lightbulb_24dp.svg";
export { default as Linkedin } from "./linkedin.svg";
export { ReactComponent as ICON_LOGOUT } from "./logout_black_24dp.svg";
export { ReactComponent as DISCORD_LOGO } from "./logo_discord.svg";
export { ReactComponent as KAKAOTALK_LOGO } from "./logo_kakaotalk.svg";
export { default as Mail } from "./mail.svg";
export { ReactComponent as ICON_MASSIVE_EVENT_BORDER } from "./massive-event_border.svg";
export { ReactComponent as ICON_MASSIVE_EVENT } from "./massive-event.svg";
export { ReactComponent as ICON_MENU } from "./menu_black_24dp.svg";
export { ReactComponent as ICON_METAMASK } from "./metamask.svg";
export { ReactComponent as ICON_MEDAL } from "./military_tech_black_24dp.svg";
export { ReactComponent as ICON_MORE_TIME } from "./more_time_black_24dp.svg";
export { ReactComponent as ICON_PAUSE } from "./pause_black_24dp.svg";
export { ReactComponent as ICON_PAYMENTS } from "./payments_black_24dp.svg";
export { ReactComponent as ICON_PEOPLE } from "./people_black_24dp.svg";
export { ReactComponent as ICON_PERSON } from "./person_black_24dp.svg";
export { ReactComponent as ICON_PERSON_REMOVE } from "./person_remove_black_24dp.svg";
export { ReactComponent as ICON_PLAY_CIRCLE } from "./play.svg";
export { ReactComponent as ICON_PLAY } from "./play_circle_outline_black_24dp.svg";
export { ReactComponent as ICON_PLUS_THIN } from "./plus.svg";
export { ReactComponent as ICON_PRESENT_RAINBOW_BORDER } from "./present_rainbow_border.svg";
export { ReactComponent as ICON_PRESENT_RAINBOW } from "./present_rainbow.svg";
export { ReactComponent as ICON_REFRESH } from "./refresh_black_24dp.svg";
export { ReactComponent as ICON_REMOVE } from "./remove_circle_outline_black_24dp.svg";
export { ReactComponent as ICON_REMOVE_FROM_PHOTOS } from "./remove_from_photos.svg";
export { ReactComponent as ICON_REPORT } from "./report.svg";
export { ReactComponent as ICON_RESTART } from "./restart_alt_black_24dp.svg";
export { ReactComponent as ICON_SEARCH } from "./search_black.svg";
export { ReactComponent as ICON_SECOND_PLACE } from "./second_place.svg";
export { ReactComponent as ICON_SEND } from "./send.svg";
export { ReactComponent as ICON_SETTING } from "./settings_black_24dp.svg";
export { ReactComponent as ICON_SKIP_NEXT } from "./skip_next_black_24dp.svg";
export { ReactComponent as ICON_SKIP_PREVIOUS } from "./skip_previous_black_24dp.svg";
export { ReactComponent as ICON_SOUND } from "./sound.svg";
export { ReactComponent as ICON_SPLIT_BAR } from "./split_bar.svg";
export { ReactComponent as ICON_STORE } from "./store.svg";
export { ReactComponent as ICON_STYLE } from "./style_fill.svg";
export { ReactComponent as ICON_SWAP } from "./swap_vert.svg";
export { ReactComponent as SYL_LOTTERY } from "./syl_lottery.svg";
export { ReactComponent as SYL_LOTTERY_MINI } from "./syl_lottery_mini.svg";
export { ReactComponent as SYLTARE_ETH_LOGO } from "./syltare_eth_logo.svg";
export { ReactComponent as SYLTARE_KP_LOGO } from "./syltare_kp_logo.svg";
export { ReactComponent as SYLTARE_USDC_LOGO } from "./syltare_usdc_logo.svg";
export { ReactComponent as SYLTARE_WUSDC_LOGO } from "./syltare_wusdc_logo.svg";
export { ReactComponent as SYLTARE_LOGO } from "./syltare_logo.svg";
export { ReactComponent as SYLTARE_LOGO_SIMPLE } from "./syltare_logo_simple.svg";
export { ReactComponent as SYLTARE_LOGO_SIMPLE_FILLED } from "./syltare_simple_filled_logo.svg";
export { ReactComponent as SYLTARE_LOGO_TEXT } from "./syltare_logo_text.svg";
export { ReactComponent as SYLTARE_LOGO_WHITE } from "./syltare_logo_white.svg";
export { ReactComponent as SYLTARE_REWARD_LOGO } from "./syltare_reward_logo.svg";
export { ReactComponent as SYLTARE_TEXT_LOGO } from "./syltare_text_logo.svg";
export { ReactComponent as ICON_THIRD_PLACE } from "./third_place.svg";
export { ReactComponent as ICON_TIMER } from "./timer_black_24dp.svg";
export { ReactComponent as ICON_TOGGLE_ON } from "./toggle_on_black_24dp.svg";
export { ReactComponent as ICON_TUTORIAL } from "./tutorial.svg";
export { default as Twitter } from "./twitter.svg";
export { ReactComponent as ICON_UNISWAP } from "./uniswap.svg";
export { ReactComponent as ICON_VERIFIED } from "./verified_black_24dp.svg";
export { ReactComponent as ICON_VOLUME_OFF } from "./volume_off_black_24dp.svg";
export { ReactComponent as ICON_VOLUME_UP } from "./volume_up_black_24dp.svg";
export { ReactComponent as ICON_WALLETCONNECT } from "./wallet-connect.svg";
export { ReactComponent as ICON_WALLET } from "./wallet.svg";
export { ReactComponent as ICON_WARNING } from "./warning_24dp.svg";
export { default as Youtube } from "./youtube.svg";
export { ReactComponent as ICON_EARTH } from "./icon_earth.svg";
export { ReactComponent as ICON_ETHER } from "./icon_ether.svg";
export { ReactComponent as ICON_FIRE } from "./icon_fire.svg";
export { ReactComponent as ICON_WATER } from "./icon_water.svg";
export { ReactComponent as ICON_WIND } from "./icon_wind.svg";
export { ReactComponent as ICON_GOBLIN } from "./goblin.svg";
export { ReactComponent as ICON_POLYGON } from "./icon_polygon.svg";
export { ReactComponent as ICON_CHECK_WHITE } from "./check_white.svg";
export { ReactComponent as ICON_EYE } from "./visibility_24dp.svg";
