import { useEffect, useState } from "react";
import { Hydrate, QueryClient, QueryClientProvider } from "react-query";
import { NextQueryParamProvider } from "next-query-params";
import { appWithTranslation } from "next-i18next";
import { Context as ResponsiveContext } from "react-responsive";

import { useRouter } from "next/router";
import { DefaultSeo } from "next-seo";
import App, { AppContext, AppProps } from "next/app";
import Layout from "@/layouts/simpleLayout";
import { Web3ReactProvider } from "@web3-react/core";
import getLibrary from "@/services/getLibrary";
import { DEFAULT_SEO } from "@/constants/seo";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";

import "./index.scss";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  //userAgent
  const userAgent = (await appContext.ctx.req) ? appContext.ctx.req?.headers["user-agent"] : navigator.userAgent;

  //Mobile
  const mobile = await userAgent?.indexOf("Mobi");

  //Mobile in pageProps
  appProps.pageProps.isMobile = (await (mobile !== -1)) ? true : false;

  return { ...appProps };
};

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: twentyFourHoursInMs,
          },
        },
      }),
  );
  const router = useRouter();

  useEffect(() => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init("2277161369109138");
        ReactPixel.pageView();

        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      });
  }, [router.events]);

  return (
    <ResponsiveContext.Provider value={{ width: pageProps.isMobile ? 375 : 1440 }}>
      <Layout>
        <DefaultSeo
          {...DEFAULT_SEO}
          additionalLinkTags={[
            {
              rel: "icon",
              href: "/syltare_logo.svg",
            },
            {
              rel: "apple-touch-icon",
              href: "/syltare_logo.svg",
              sizes: "76x76",
            },
          ]}
        />
        <Web3ReactProvider getLibrary={getLibrary}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <NextQueryParamProvider>
                <Component {...pageProps} />
              </NextQueryParamProvider>
            </Hydrate>
          </QueryClientProvider>
        </Web3ReactProvider>
      </Layout>
    </ResponsiveContext.Provider>
  );
}

export default appWithTranslation(MyApp);
