import { useEffect, useState } from "react";
import classNames from "classnames/bind";
import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { useTranslation } from "next-i18next";
import headerNavLinks from "@/constants/headerNavLinks";
import { ICON_OPENSEA } from "@syl-codes/ui/svgs";
import { MEDIA_LINK } from "@/constants/media";
import CustomLink from "../link";
import IconList from "../icon-list";
import styles from "./mobile.module.scss";

const cx = classNames.bind(styles);

interface Props {
  isOpen: boolean;
  isHomePage: boolean;
  onClick: () => void;
}

export default function NavbarMobile({ isOpen, isHomePage, onClick }: Props) {
  const router = useRouter();
  const { t } = useTranslation("common");
  const target = router.asPath.split("?")[0];
  const [element, setElement] = useState<HTMLElement | null>();

  const targetIndex = Math.max(
    headerNavLinks.findIndex((link) => target.startsWith(link.href)),
    0,
  );

  useEffect(() => {
    setElement(document.getElementById("header"));
  }, []);

  const handleClickLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    toast.error(t("open"), {
      position: "top-center",
      theme: "colored",
      autoClose: 2000,
    });
  };

  return (
    <div className={cx("wrapper")}>
      <ul
        className={cx("menu", { home: isHomePage })}
        style={{
          top: isOpen ? (element?.offsetHeight || 0) - 1 : "-2000%",
          height: isOpen ? window.innerHeight - (element?.offsetHeight || 0) : 0,
        }}
      >
        {headerNavLinks.map((link, i) => {
          const soon = link.href === "#";

          if (link.title === "WHITE PAPER" && router.locale === "ko") {
            return (
              <li key={link.href + target + i} className={cx("item")}>
                <CustomLink
                  className={cx("link", { current: targetIndex === i })}
                  href={link.href.replace("_en_", "_kr_")}
                  onClick={onClick}
                >
                  {link.title}
                </CustomLink>
              </li>
            );
          }

          return (
            <li key={link.href + target + i} className={cx("item")}>
              <CustomLink
                className={cx("link", { current: targetIndex === i, soon })}
                href={link.href}
                onClick={soon ? handleClickLink : onClick}
              >
                {link.title}
              </CustomLink>
            </li>
          );
        })}

        <li className={cx("item", "opensea_wrapper")}>
          <CustomLink className={cx("link")} href={MEDIA_LINK.OPENSEA_ETH}>
            <ICON_OPENSEA height={26} />
          </CustomLink>
        </li>
        <li className={cx("item", "icon_wrapper")}>
          <IconList {...{ cx, fill: "black" }} />
        </li>
        <li className={cx("item", "lang_wrap")}>
          {["ko", "en", "vi"].map((currentLang) => (
            <CustomLink
              locale={currentLang}
              href={router.asPath}
              key={router.locale + currentLang}
              onClick={onClick}
              className={cx("lang", { red: router.locale === currentLang })}
            >
              {currentLang.toUpperCase()}
            </CustomLink>
          ))}
        </li>
      </ul>
    </div>
  );
}
