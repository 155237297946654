import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useWindowSize } from "react-use";

const useMobileMediaQuery = () => useMediaQuery({ maxWidth: 930 });

export function useIsMobile() {
  const { width } = useWindowSize();
  const mobile = useMobileMediaQuery();
  const [isMobile, setIsMobile] = useState(mobile);

  useEffect(() => {
    setIsMobile(width < 930);
  }, [width]);

  return isMobile;
}
