import { useEffect, useState } from "react";
import classnames from "classnames/bind";
import { swing, Scrollchor } from "react-scrollchor";

import { useRouter } from "next/router";
import { toast } from "react-toastify";
import { useTranslation } from "next-i18next";
import headerNavLinks from "@/constants/headerNavLinks";

import { Link } from "@/components/index";
import styles from "./navbar.module.scss";

const cx = classnames.bind(styles);

export default function Navbar() {
  const router = useRouter();
  const [target, setTarget] = useState(router.asPath.split("?")[0]);

  const { t } = useTranslation("common");

  const handleAfterAnimate = (url: string) => () => {
    const [, hash] = url.split("#");
    window.location.hash = hash;
    setTarget(url);
  };

  const handleClickLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    toast.error(t("open"), {
      position: "top-center",
      theme: "colored",
      autoClose: 2000,
    });
  };

  const isHomePage = router.pathname === "/home";

  useEffect(() => {
    setTarget(router.asPath.split("?")[0]);
  }, [router]);

  const targetIndex = Math.max(
    headerNavLinks.findIndex((link) => target.startsWith(link.href)),
    0,
  );

  return (
    <>
      {headerNavLinks.map((link, i) => {
        const hash = link.href.split("#")[1];
        const soon = link.href === "#";

        if (link.title === "WHITE PAPER" && router.locale === "ko") {
          return (
            <Link
              key={link.href + target + i}
              className={cx("link", { current: targetIndex === i })}
              href={link.href.replace("_en_", "_kr_")}
            >
              {link.title}
            </Link>
          );
        }

        return (
          <span key={link.href + target + i}>
            {isHomePage && hash ? (
              <Scrollchor
                className={cx("link", { current: targetIndex === i, soon })}
                afterAnimate={handleAfterAnimate(link.href)}
                to={hash}
                animate={{
                  offset: -125, //header fixed 높이
                  duration: 800,
                  easing: swing,
                }}
              >
                {link.title}
              </Scrollchor>
            ) : (
              <Link
                className={cx("link", { current: targetIndex === i, soon })}
                onClick={soon ? handleClickLink : undefined}
                href={link.href}
              >
                {link.title}
              </Link>
            )}
          </span>
        );
      })}
    </>
  );
}
