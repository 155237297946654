export enum MEDIA_LINK {
  KAKAOTALK = "https://open.kakao.com/o/g9qbzfRd",
  DISCORD = "https://discord.gg/Cb2tNa4zte",
  TWITTER = "https://twitter.com/syltare",
  TELEGRAM = "https://t.me/syltare",
  MEDIUM = "https://syltare.medium.com/",
  YOUTUBE = "https://www.youtube.com/channel/UC9geDio_bLVSxJwBE0XIGcw",
  OPENSEA_ETH = "https://opensea.io/collection/syltare-dawn-of-east",
  OPENSEA_UNREVEALED = "https://opensea.io/collection/syltare-official",
  OPENSEA_KLAYTN = "https://opensea.io/collection/syltare-dawn-of-east-klaytn",
  TUTORIAL = "https://www.youtube.com/watch?v=HPEOnG6ByaI&ab_channel=SYLTARE%EC%8B%A4%ED%83%80%EB%9E%98",
  TUTORIAL_BOOK_EN = "https://syltaretutorial.oopy.io/",
  TUTORIAL_BOOK_KR = "https://syltareguidebook-kr.oopy.io/",
  TUTORIAL_BOOK_VI = "https://syltareguidebook-vn.oopy.io/",
  PLAY = "https://game.syltare.com/?utm_source=SYLTARE&utm_medium=Web&utm_campaign=syltare_mainhome_gamehome&utm_content=syltare",
  SYLTOKEN = "https://etherscan.io/token/0x92925Acf2771Bc59143228499f9610FE5176eb9C",
  UNISWAP_CHART = "https://info.uniswap.org/#/tokens/0x92925Acf2771Bc59143228499f9610FE5176eb9C",
  UNISWAP_SWAP = "https://app.uniswap.org/#/swap?inputCurrency=eth&outputCurrency=0x92925acf2771bc59143228499f9610fe5176eb9c&chain=mainnet",
  INFO = "https://info.syltare.com/ANNOUNCEMENT",
}
