import { useState } from "react";
import classNames from "classnames/bind";
import { useRouter } from "next/router";
import Hamburger from "hamburger-react";

import { useLockBodyScroll } from "react-use";
import { useIsMobile } from "@syl-codes/modules/hooks/useResponsive";
import { ICON_INFO, ICON_TUTORIAL, SYLTARE_LOGO } from "@syl-codes/ui/svgs";
import { MEDIA_LINK } from "@/constants/media";
import CustomLink from "../link";
import Navbar from "../navbar";
import NavbarMobile from "../navbar/mobile";
import Links from "./links";

import styles from "./header.module.scss";

const cx = classNames.bind(styles);

const tutorialMap = {
  ko: MEDIA_LINK.TUTORIAL_BOOK_KR,
  en: MEDIA_LINK.TUTORIAL_BOOK_EN,
  vi: MEDIA_LINK.TUTORIAL_BOOK_VI,
};

export default function Header() {
  const router = useRouter();
  const isMobile = useIsMobile();
  const [isOpen, setOpen] = useState(false);
  useLockBodyScroll(isOpen);

  const handleClickMobileNav = () => {
    setOpen(false);
  };

  const isHomePage = router.pathname === "/home";

  const tutorialLink = tutorialMap[router.locale as keyof typeof tutorialMap];

  return (
    <div className={cx("header", { home: isHomePage })} id="header">
      <CustomLink href="/" className={cx("syl-home")}>
        <SYLTARE_LOGO width="30" height="30" />
      </CustomLink>

      {isMobile && (
        <>
          <CustomLink href={MEDIA_LINK.INFO} className={cx("button", "outlined")}>
            INFO
          </CustomLink>
          <CustomLink href={tutorialLink} className={cx("button", "outlined")}>
            GUIDE
          </CustomLink>

          <CustomLink href={MEDIA_LINK.PLAY} className={cx("button", "filled")}>
            <SYLTARE_LOGO fill="#fff" className={cx("svg")} />
            PLAY NOW
          </CustomLink>
        </>
      )}
      <div className={cx("wrapper")}>
        {isMobile ? (
          <NavbarMobile isOpen={isOpen} isHomePage={isHomePage} onClick={handleClickMobileNav} />
        ) : (
          <Navbar />
        )}

        {!isMobile && <Links />}

        {isMobile && <Hamburger toggled={isOpen} toggle={setOpen} color="#797979" size={22.5} />}
        {!isMobile && (
          <div className={cx("button-group")}>
            <CustomLink href={MEDIA_LINK.INFO} className={cx("button", "outlined")}>
              <ICON_INFO className={cx("svg")} />
              INFO
            </CustomLink>
            <CustomLink href={tutorialLink} className={cx("button", "outlined")}>
              <ICON_TUTORIAL className={cx("svg")} />
              GUIDE
            </CustomLink>

            <CustomLink href={MEDIA_LINK.PLAY} className={cx("button", "filled")}>
              <SYLTARE_LOGO fill="#fff" className={cx("svg", "syl")} />
              PLAY NOW
            </CustomLink>
          </div>
        )}
      </div>
    </div>
  );
}
