import { Argument } from "classnames";
import { MEDIA_LINK } from "@/constants/media";
import { ICON_DISCORD, ICON_TELEGRAM, ICON_TWITTER, ICON_YOUTUBE } from "@syl-codes/ui/svgs";
import CustomLink from "../link";

interface Props {
  fill: string;
  cx: (...args: Argument[]) => string;
}

export default function IconList({ cx, fill }: Props) {
  return (
    <>
      <CustomLink href={MEDIA_LINK.DISCORD} className={cx("icon")}>
        <ICON_DISCORD width={20} height={20} fill={fill} />
      </CustomLink>
      <CustomLink href={MEDIA_LINK.TWITTER} className={cx("icon")}>
        <ICON_TWITTER width={20} height={20} fill={fill} />
      </CustomLink>
      <CustomLink href={MEDIA_LINK.TELEGRAM} className={cx("icon")}>
        <ICON_TELEGRAM width={20} height={20} fill={fill} />
      </CustomLink>
      <CustomLink href={MEDIA_LINK.YOUTUBE} className={cx("icon")}>
        <ICON_YOUTUBE width={20} height={20} fill={fill} />
      </CustomLink>
    </>
  );
}
