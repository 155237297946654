import Link, { LinkProps } from "next/link";

type Props = {
  href?: string;
  children: React.ReactNode;
  className?: string;
} & Partial<LinkProps>;

const CustomLink = ({ href = "/", locale, ...rest }: Props) => {
  const isInternalLink = href?.startsWith("/");
  const isAnchorLink = href?.startsWith("#");

  if (isInternalLink) {
    return (
      <Link href={href} locale={locale}>
        <a {...rest} />
      </Link>
    );
  }

  if (isAnchorLink) {
    return <a href={href} {...rest} />;
  }

  return <a target="_blank" rel="noopener noreferrer" href={href} {...rest} />;
};

export default CustomLink;
