import classNames from "classnames/bind";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { Popover } from "@nextui-org/react";
import IconList from "../icon-list";
import CustomLink from "../link";
import styles from "./header.module.scss";

const cx = classNames.bind(styles);

export default function Links() {
  const {
    i18n: { language },
  } = useTranslation();

  const router = useRouter();
  return (
    <>
      <Popover>
        <Popover.Trigger>
          <span className={cx("link")}>LINKS</span>
        </Popover.Trigger>
        <Popover.Content>
          <div className={cx("icon_wrapper")}>
            <IconList {...{ cx, fill: "#C1CEE3" }} />
          </div>
        </Popover.Content>
      </Popover>

      <Popover>
        <Popover.Trigger>
          <span className={cx("global", "link")}>
            <Image priority src="/global.svg" width={24} height={24} />
            {language}
          </span>
        </Popover.Trigger>
        <Popover.Content>
          <div className={cx("link_wrapper")}>
            <CustomLink href={router.asPath} locale="en" className={cx("text", { selected: router.locale === "en" })}>
              ENGLISH
            </CustomLink>
            <CustomLink href={router.asPath} locale="ko" className={cx("text", { selected: router.locale === "ko" })}>
              KOREAN
            </CustomLink>
            <CustomLink href={router.asPath} locale="vi" className={cx("text", { selected: router.locale === "vi" })}>
              VIETNAMESE
            </CustomLink>
          </div>
        </Popover.Content>
      </Popover>
    </>
  );
}
