import { ToastContainer } from "react-toastify";
import { Footer, Header } from "@/components/index";

interface Props {
  children: React.ReactNode;
}

export default function Layout({ children }: Props) {
  return (
    <>
      <Header />
      {children}
      <ToastContainer />
      <Footer />
    </>
  );
}
