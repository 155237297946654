const headerNavLinks = [
  { href: "/home#home", title: "HOME" },
  { href: "https://bit.ly/syltare_whitepaper_en_1_0_1", title: "WHITE PAPER" },
  { href: "/story", title: "STORY" },
  { href: "#", title: "SYL CARDS" },
  { href: "/home#milestone", title: "MILESTONE" },
  // { href: "/home#team", title: "TEAM" },
  { href: "/home#faq", title: "FAQ" },
];

export default headerNavLinks;
